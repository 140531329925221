<template>
    <router-link :to="'/coaches-trainers/' + data.slug" class="teacher-part trainer-and-coach-part part">
        <div class="thumb-side" v-if="data.templateContentMedia &&
            data.templateContentMedia.overviewImage
            ">
            <img :alt="data.templateContentMedia.overviewImage.title" :src="data.templateContentMedia.overviewImage.url" />
        </div>
        <div class="content-side">
            <div class="meta-wrapper">
                <h3 class="part-title">
                    {{ data.templateContentFields.displayTitle }}
                </h3>
                <div class="part-desc" v-html="data.templateContentFields.overviewShortDescription"></div>
            </div>
        </div>
    </router-link>
</template>

<script>
//import { fetchCmsMedia } from "@streampac.io/chef_sp_1";

export default {
    data() {
        return {
            mediaAsset: null,
        };
    },
    props: ["data"],
    created() {
        //this.resolveCmsMedia();
    },
    inject: ["isAuthenticated"],
    methods: {
        // resolveCmsMedia() {
        // 	if (this.data && this.data.templateContentFields.overviewImage !== "") {
        // 		fetchCmsMedia(
        // 			this.isAuthenticated,
        // 			this.data.templateContentFields.overviewImage
        // 		).then((response) => {
        // 			this.mediaAsset = response;
        // 		});
        // 	}
        // },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>

<style lang="scss">
.teacher-part .part-desc {
    p {
        @include font-size(14);
        font-weight: $regularWeight;
    }
}
</style>


